<template>
  <a
    :href="currentStore.href"
    class="store-button"
    rel="noreferrer nofollow"
    target="_blank"
    @click.stop="sendSoguAppClickEvent(currentStore.soguEvent)"
  >
    <span class="store-button__text"> {{ currentStore.text }} <br />в {{ currentStore.name }}</span>

    <component
      :is="currentStore.icon"
      width="20"
      height="24"
      color="var(--comp-button-outline-color-icon-neutral)"
    />
  </a>
</template>

<script setup lang="ts">
  import type { Component } from 'vue';

  import AppleIcon from '@/shared/ui/icons/AppleIcon.vue';
  import GooglePlayIcon from '@/shared/ui/icons/GooglePlayIcon.vue';

  interface Props {
    storeType: 'google' | 'apple';
  }

  interface StoreItem {
    text: string;
    name: string;
    href: string;
    icon: Component;
    soguEvent: string;
  }

  interface Stores {
    google: StoreItem;
    apple: StoreItem;
  }

  const props = withDefaults(defineProps<Props>(), {
    storeType: 'google',
  });

  const stores = reactive<Stores>({
    google: {
      text: 'Доступно',
      name: 'Google Play',
      href: 'https://play.google.com/store/apps/details?id=ru.tripster.tripster',
      icon: markRaw(GooglePlayIcon),
      soguEvent: 'android',
    },
    apple: {
      text: 'Загрузите',
      name: 'App Store',
      href: 'https://apps.apple.com/ru/app/tripster-необычные-экскурсии/id1479200934',
      icon: markRaw(AppleIcon),
      soguEvent: 'ios',
    },
  });

  const currentStore = computed<StoreItem>(() => stores[props.storeType]);

  const sendSoguAppClickEvent = (event: string) => {
    // TODO: add sogu event
    console.log('sendSoguAppClickEvent: ', event);
  };
</script>

<style scoped lang="scss">
  .store-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 108px;
    height: 40px;
    padding: 8px 8px 8px 12px;
    border: 1px solid var(--comp-button-outline-color-border-neutral);
    border-radius: 8px;
    color: var(--comp-button-outline-color-text-neutral);
    text-decoration: none;

    &:hover {
      border-color: var(--comp-button-outline-color-border-neutral-hovered);
      cursor: pointer;
    }
  }

  .store-button__text {
    font-weight: 600;
    font-style: normal;
    font-size: 9px;
    line-height: 10px;
  }
</style>
