<template>
  <div>
    <component
      :is="currentLayoutComponent"
      v-if="currentLayoutComponent"
    >
      <slot></slot>
    </component>
  </div>
</template>

<script setup lang="ts">
  import { useLayout } from './lib';

  const { currentLayoutComponent } = storeToRefs(useLayout());
</script>
