import { setErrorHandler } from '@experience/core-hostapp-share-lib';
import * as Sentry from '@sentry/vue';
import { useRuntimeConfig } from 'virtual:runtime-config';
import type { App } from 'vue';

interface RuntimeConfig {
  VITE_RUNTIME_SENTRY_DSN_CLIENT?: string;
  VITE_RUNTIME_ENVIRONMENT?: string;
}

export const install = (app: App) => {
  const { VITE_RUNTIME_SENTRY_DSN_CLIENT, VITE_RUNTIME_ENVIRONMENT } = useRuntimeConfig() as RuntimeConfig;
  if (VITE_RUNTIME_SENTRY_DSN_CLIENT) {
    Sentry.init({
      app,
      dsn: VITE_RUNTIME_SENTRY_DSN_CLIENT,
      environment: VITE_RUNTIME_ENVIRONMENT,
      integrations: [],
    });

    setErrorHandler((error, tags) => {
      Sentry.captureException(error, (scope) => {
        Object.entries(tags).forEach(([key, value]) => {
          scope.setTag(key, value);
        });

        return scope;
      });
    });

    // Обработка ошибок внутри самого host-app
    app.config.errorHandler = (err) => {
      Sentry.captureException(err, (scope) => {
        scope.setTag('mf-tag', 'host');

        return scope;
      });

      throw err;
    };
  }
};
