/**
 * Устанавливает cookie идентификатора устройства, если необходимо.
 *
 * @param deviceId идентификатор устройства
 */
export const setDeviceIdCookie = (deviceId: string | null | undefined): void => {
  const date = new Date();

  date.setMonth(date.getMonth() + 3); // добавляем время жизни куки - 3 месяца

  document.cookie = `device_id=${deviceId ?? ''}; path=/; SameSite=Lax; expires=${deviceId ? date : 'Thu, 01 Jan 1970 00:00:00 GMT'};`;
};
