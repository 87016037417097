import {
  __federation_method_getRemote,
  __federation_method_setRemote,
  // @ts-expect-error виртуальная зависимость TODO добавить типизацию
} from '__federation__';
import { useRuntimeConfig } from 'virtual:runtime-config';
import type { Component } from 'vue';

const { VITE_RUNTIME_IS_DEV } = useRuntimeConfig<false>();

/**
 * Неймспейсы микрофронтендов.
 * Под этими неймспейсами также хранятся переменные окружения для микрофронтендов.
 * В качестве значений используются project_slug из gitlab
 */
export const MF_NAMESPACES = {
  LISTING: 'experience-mf-listing',
  PARTNERS_PERSONAL_ACCOUNT: 'partners-personal-account',
  GUIDE_ACCOUNT: 'frontend-guide-account-app',
  GUIDE_ACCOUNT_ORDERS: 'guide-account-orders',
  GUIDE_ACCOUNT_EXPERIENCE: 'guide-account-experiences',
  TRAVELER_ORDERS: 'frontend-experience-app-traveler-orders',
  TRAVELER_ORDER: 'experience-mf-traveler-order',
  TRAVELER_FAVORITES: 'frontend-experience-app-favorites',
  JOURNAL: 'frontend-journal-app',
  TEST_APP: 'mf-test-app',
  SEO_CATEGORY_LISTING: 'seo-category-listing',
  PARTNERS_LANDING: 'frontend-partners-landing',
  HOME: 'experience-mf-home',
  TAGS_MODERATION: 'experience-mf-tags-moderation',
  SIGHTS: 'site-sights-mfe',
} as const;

/**
 * Функция для получения базового пути.
 * Для локальной разработки может потребоваться задать другой путь с другим портом
 */
const getBasePath = (localhost: string = 'http://localhost:8005') => {
  return VITE_RUNTIME_IS_DEV ? localhost : window.location.origin;
};

/**
 * Конфигурация подключаемых микрофронтендов.
 * в данном случае приведен пример асинхронного подключения микрофронтендов.
 * Учитывает возможность динамического подключения микрофронтендов
 * при A/B тестировании, когда перед инициализацией приложения будет необходимо запросить активные эксперименты,
 * и по полученным данным собрать url на микрофронтенд по паттерну
 * <project_slug>/experiemnts/<experiment>/<variant>/remoteEntry.js
 */
const remotesConfig = ref({
  [MF_NAMESPACES.LISTING]: {
    url: `${getBasePath()}/mfs/${MF_NAMESPACES.LISTING}`,
    name: MF_NAMESPACES.LISTING,
    modules: {
      Listing: {
        moduleName: 'Listing',
        module: './Listing',
        isPage: true,
      },
    },
  },
  [MF_NAMESPACES.PARTNERS_PERSONAL_ACCOUNT]: {
    url: `${getBasePath()}/mfs/${MF_NAMESPACES.PARTNERS_PERSONAL_ACCOUNT}`,
    name: MF_NAMESPACES.PARTNERS_PERSONAL_ACCOUNT,
    modules: {
      PartnersPersonalAccountSales: {
        moduleName: 'PartnersPersonalAccountSales',
        module: './PartnersPersonalAccountSales',
        isPage: true,
      },
      PartnersPersonalAccountFees: {
        moduleName: 'PartnersPersonalAccountFees',
        module: './PartnersPersonalAccountFees',
        isPage: true,
      },
      PartnersPersonalAccountPayments: {
        moduleName: 'PartnersPersonalAccountPayments',
        module: './PartnersPersonalAccountPayments',
        isPage: true,
      },
      PartnersPersonalAccountOrders: {
        moduleName: 'PartnersPersonalAccountOrders',
        module: './PartnersPersonalAccountOrders',
        isPage: true,
      },
      PartnersPersonalAccountTools: {
        moduleName: 'PartnersPersonalAccountTools',
        module: './PartnersPersonalAccountTools',
        isPage: true,
      },
      PartnersPersonalAccountInstruction: {
        moduleName: 'PartnersPersonalAccountInstruction',
        module: './PartnersPersonalAccountInstruction',
        isPage: true,
      },
    },
  },
  [MF_NAMESPACES.GUIDE_ACCOUNT]: {
    url: `${getBasePath()}/mfs/${MF_NAMESPACES.GUIDE_ACCOUNT}`,
    name: MF_NAMESPACES.GUIDE_ACCOUNT,
    modules: {
      GuideAccount: {
        moduleName: 'GuideAccount',
        module: './account',
        isPage: true,
      },
      GuideAccountStats: {
        moduleName: 'GuideAccountStats',
        module: './stats',
        isPage: true,
      },
      GuideAccountPromotion: {
        moduleName: 'GuideAccountPromotion',
        module: './promotion',
        isPage: true,
      },
    },
  },
  [MF_NAMESPACES.GUIDE_ACCOUNT_EXPERIENCE]: {
    url: `${getBasePath()}/mfs/${MF_NAMESPACES.GUIDE_ACCOUNT_EXPERIENCE}`,
    name: MF_NAMESPACES.GUIDE_ACCOUNT_EXPERIENCE,
    modules: {
      GuideAccountExperiences: {
        moduleName: 'GuideAccountExperiences',
        module: './experiences',
        isPage: true,
      },
    },
  },
  [MF_NAMESPACES.GUIDE_ACCOUNT_ORDERS]: {
    url: `${getBasePath()}/mfs/${MF_NAMESPACES.GUIDE_ACCOUNT_ORDERS}`,
    name: MF_NAMESPACES.GUIDE_ACCOUNT_ORDERS,
    modules: {
      GuideAccountEvents: {
        moduleName: 'GuideAccountOrders',
        module: './events',
        isPage: true,
      },
      GuideAccountGroupEvent: {
        moduleName: 'GuideAccountGroupEvent',
        module: './groupEvent',
        isPage: true,
      },
      GuideAccountOrder: {
        moduleName: 'GuideAccountOrder',
        module: './order',
        isPage: true,
      },
      GuideAccountOrderCancel: {
        moduleName: 'GuideAccountOrderCancel',
        module: './cancel',
        isPage: true,
      },
      GuideAccountOrderCancelTraveller: {
        moduleName: 'GuideAccountOrderCancelTraveller',
        module: './cancel-traveller',
        isPage: true,
      },
    },
  },
  [MF_NAMESPACES.TRAVELER_ORDERS]: {
    url: `${getBasePath()}/mfs/${MF_NAMESPACES.TRAVELER_ORDERS}/default`,
    name: MF_NAMESPACES.TRAVELER_ORDERS,
    modules: {
      TravelerOrdersPage: {
        moduleName: 'TravelerOrdersPage',
        module: './TravelerOrdersPage',
        isPage: true,
      },
      TravelerOrdersOrdersPage: {
        moduleName: 'TravelerOrdersOrdersPage',
        module: './TravelerOrdersOrdersPage',
        isPage: true,
      },
      TravelerOrdersBonusesPage: {
        moduleName: 'TravelerOrdersBonusesPage',
        module: './TravelerOrdersBonusesPage',
        isPage: true,
      },
      TravelerOrderPage: {
        moduleName: 'TravelerOrderPage',
        module: './TravelerOrderPage',
        isPage: true,
      },
    },
  },
  [MF_NAMESPACES.TRAVELER_ORDER]: {
    url: `${getBasePath()}/mfs/${MF_NAMESPACES.TRAVELER_ORDER}`,
    name: MF_NAMESPACES.TRAVELER_ORDERS,
    modules: {
      TravelerOrderPage: {
        moduleName: 'TravelerOrderPage',
        module: './TravelerOrderPage',
        isPage: true,
      },
    },
  },
  [MF_NAMESPACES.TRAVELER_FAVORITES]: {
    url: `${getBasePath()}/mfs/${MF_NAMESPACES.TRAVELER_FAVORITES}/default`,
    name: MF_NAMESPACES.TRAVELER_FAVORITES,
    modules: {
      TravelerFavoritesPage: {
        moduleName: 'TravelerFavoritesPage',
        module: './TravelerFavoritesPage',
        isPage: true,
      },
    },
  },
  [MF_NAMESPACES.JOURNAL]: {
    url: `${getBasePath()}/mfs/${MF_NAMESPACES.JOURNAL}`,
    name: MF_NAMESPACES.JOURNAL,
    modules: {
      JournalPage: {
        moduleName: 'Journal',
        module: './Journal',
        isPage: true,
      },
    },
  },
  [MF_NAMESPACES.TEST_APP]: {
    url: `${getBasePath()}/mfs/${MF_NAMESPACES.TEST_APP}/default`,
    name: MF_NAMESPACES.TEST_APP,
    modules: {
      Example: {
        moduleName: 'Example',
        module: './Example',
        isPage: true,
      },
    },
  },
  [MF_NAMESPACES.SEO_CATEGORY_LISTING]: {
    url: `${getBasePath()}/mfs/${MF_NAMESPACES.SEO_CATEGORY_LISTING}`,
    name: MF_NAMESPACES.SEO_CATEGORY_LISTING,
    modules: {
      SeoCategoryListing: {
        moduleName: 'SeoCategoryListing',
        module: './SeoCategoryListing',
        isPage: true,
      },
    },
  },
  [MF_NAMESPACES.PARTNERS_LANDING]: {
    url: `${getBasePath()}/mfs/${MF_NAMESPACES.PARTNERS_LANDING}`,
    name: MF_NAMESPACES.PARTNERS_LANDING,
    modules: {
      PartnersLanding: {
        moduleName: 'PartnersLanding',
        module: './PartnersLanding',
        isPage: true,
      },
      TravelAgentsLanding: {
        moduleName: 'TravelAgentsLanding',
        module: './TravelAgentsLanding',
        isPage: true,
      },
    },
  },
  [MF_NAMESPACES.HOME]: {
    url: `${getBasePath()}/mfs/${MF_NAMESPACES.HOME}`,
    name: MF_NAMESPACES.HOME,
    modules: {
      HomePage: {
        moduleName: 'Home',
        module: './Home',
        isPage: true,
      },
    },
  },
  [MF_NAMESPACES.TAGS_MODERATION]: {
    url: `${getBasePath()}/mfs/${MF_NAMESPACES.TAGS_MODERATION}`,
    name: MF_NAMESPACES.TAGS_MODERATION,
    modules: {
      TagsModeration: {
        moduleName: 'TagsModeration',
        module: './TagsModeration',
        isPage: true,
      },
    },
  },
  [MF_NAMESPACES.SIGHTS]: {
    url: `${getBasePath()}/mfs/${MF_NAMESPACES.SIGHTS}`,
    name: MF_NAMESPACES.SIGHTS,
    modules: {
      SightsPage: {
        moduleName: 'Sights',
        module: './Sights',
        isPage: true,
      },
    },
  },
});

type RemoteKey = keyof typeof remotesConfig.value;
interface RemoteModule {
  moduleName: string;
  module: string;
  isPage: boolean;
}
interface RemoteConfig {
  url: string;
  name: string;
  modules: Record<string, RemoteModule>;
}
type RemotesType<T extends Record<string, RemoteConfig>> = {
  [K in keyof T]: {
    [M in keyof T[K]['modules']]: () => Promise<Component> | Component;
  };
};

interface Remote {
  url: string; // Ссылка на микрофронтенд
  name: string; // Имя микрофронтенда, задается для того, чтобы микрофронтенд можно было импортировать по ключу, например, в роутере: component: () => remotes.value.travelerOrders.TravelerOrdersOrdersPage()
  module: string; // Путь к экспортируемому модулю, указанный в конфиге конкретного микрофронтенда в поле exposes (ключ)
  isPage?: boolean; // Флаг указывающий на то, что микрофронтенд является страницей. Необходим для корректной загрузки компонентов
}

export const useRemoteApps = () => {
  const getRemoteApp = ({ url, name, module, isPage }: Remote): Record<string, Promise<Component> | Component> => {
    __federation_method_setRemote(name, {
      url: () => Promise.resolve(url),
      format: 'esm',
      from: 'vite',
    });

    const app = __federation_method_getRemote(name, module);
    if (isPage) {
      return app;
    }

    return defineAsyncComponent(() => app);
  };

  const remotes = computed<RemotesType<typeof remotesConfig.value>>(() => {
    const res = {} as RemotesType<typeof remotesConfig.value>;
    Object.keys(remotesConfig.value).forEach((key) => {
      const remote = remotesConfig.value[key as RemoteKey];
      // @ts-expect-error поправить тип
      res[key as RemoteKey] = Object.entries(remote.modules).reduce(
        (acc, [moduleKey, value]) => {
          acc[moduleKey] = () =>
            getRemoteApp({
              url: `${remote.url}/assets/remoteEntry.js?${+new Date()}`,
              name: value.moduleName,
              module: value.module,
              isPage: value.isPage,
            });

          return acc;
        },
        {} as Record<string, () => Promise<Component> | Component>,
      );
    });

    return res;
  });

  type MFNamespace = (typeof MF_NAMESPACES)[keyof typeof MF_NAMESPACES];

  const setupRemotes = async () => {
    const setupEnvPromises: Promise<unknown>[] = [];
    const setupRemoteEnv = async (name: MFNamespace, url: string) => {
      try {
        if (!url) {
          return;
        }

        try {
          const response = await fetch(`${url}/config.json`);
          if (!response.ok) {
            return {};
          }
          window.__runtimeConfig__[name] = await response.json();
        } catch (e) {
          return e;
        }
      } catch (error) {
        console.groupCollapsed(
          `%cFetching remote config [${name}] failed`,
          'background: LemonChiffon; color: black; font-weight: 600; padding: 4px 8px; border-radius: 6px; font-size: 12px;',
        );
        console.error(error);
        console.groupEnd();

        window.__runtimeConfig__[name] = {};
      }
    };
    Object.keys(remotesConfig.value).forEach((key) => {
      setupEnvPromises.push(setupRemoteEnv(key as MFNamespace, remotesConfig.value[key as MFNamespace].url));
    });
    /**
     * Загружаем переменные окружения
     */
    await Promise.all(setupEnvPromises);
  };

  return {
    setupRemotes,
    remotes,
  };
};
