import { createI18n } from '@experience/i18n';
import type { App } from 'vue';

export const install = (app: App) => {
  const i18n = createI18n({
    legacy: false,
    locale: 'ru', // ru или en
    fallbackLocale: 'ru',
  });

  app.use(i18n);
};
