<template>
  <div class="footer-info">
    <footer-social-icons size="56" />
    <span class="footer-info__text">
      Обзоры необычных мест по всему миру,<br />
      новинки и скидки в наших соцсетях
    </span>
    <app-buttons />
    <common-info-menu />
    <span class="footer-info__company">
      © 2013—{{ currentYear }}, Tripster Limited. Archiepiskopou Makariou III Avenue 155, Proteas House, 3026 Limassol,
      Cyprus
    </span>
    <pay-systems />
  </div>
</template>

<script setup lang="ts">
  import AppButtons from './AppButtons.vue';
  import CommonInfoMenu from './CommonInfoMenu.vue';
  import FooterSocialIcons from './FooterSocialIcons.vue';
  import PaySystems from './PaySystems.vue';

  const currentYear = ref<number>(new Date().getFullYear());
</script>

<style scoped lang="scss">
  @use '@/styles/global/mixins';

  .footer-info {
    display: flex;
    flex: 6;
    flex-direction: column;

    @include mixins.tablet {
      flex: 5;
    }

    @include mixins.mobile {
      max-width: 100%;
    }
  }

  .footer-info__text {
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
  }

  .footer-info__company {
    max-width: 390px;
    margin-top: 24px;
    line-height: 20px;
  }
</style>
