import type { RouteRecordRaw } from 'vue-router';

import { MF_NAMESPACES, useRemoteApps } from '@/useRemoteApps';

const { remotes } = useRemoteApps();

const getPath = (path: string) => import.meta.env.BASE_URL + path;

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    alias: '/va',
    name: 'home',
    component: () => remotes.value[MF_NAMESPACES.HOME].HomePage(),
  },
  {
    path: getPath('experience/:citySlug'),
    name: 'cityListing',
    component: () => remotes.value[MF_NAMESPACES.LISTING].Listing(),
    meta: {
      title: 'Листинг экскурсий по городу',
      destinationType: 'city',
    },
  },
  {
    path: getPath('experience/:citySlug/:tag'),
    name: 'cityTagListing',
    component: () => remotes.value[MF_NAMESPACES.LISTING].Listing(),
    meta: {
      title: 'Листинг экскурсий по городу по тагу',
      destinationType: 'city',
    },
  },
  {
    path: getPath('tours/:countrySlug'),
    name: 'countryListing',
    component: () => remotes.value[MF_NAMESPACES.LISTING].Listing(),
    meta: {
      title: 'Листинг туров по стране',
      destinationType: 'country',
    },
    children: [
      {
        component: () => remotes.value[MF_NAMESPACES.LISTING].Listing(),
        meta: {
          checkGeoParam: true,
          title: 'Листинг туров по стране по тагу',
          destinationType: 'country',
          hasTours: true,
        },
        name: 'tours-country-tag',
        path: `tags/:tag/`,
      },
    ],
  },
  {
    path: getPath('tours/:country/:regionSlug'),
    name: 'regionListing',
    component: () => remotes.value[MF_NAMESPACES.LISTING].Listing(),
    meta: {
      title: 'Листинг туров по региону',
      destinationType: 'region',
    },
  },
  {
    path: getPath('tours/:country/:regionSlug/:tag'),
    name: 'regionTagListing',
    component: () => remotes.value[MF_NAMESPACES.LISTING].Listing(),
    meta: {
      title: 'Листинг туров по региону по тагу',
      destinationType: 'region',
    },
  },
  {
    path: getPath('tours/:country/:tag'),
    name: 'countryTagListing',
    component: () => remotes.value[MF_NAMESPACES.LISTING].Listing(),
    meta: {
      title: 'Листинг туров по стране по тагу',
      destinationType: 'country',
    },
  },
  {
    path: getPath('destinations/:countrySlug'),
    name: 'destinationCountryListing',
    component: () => remotes.value[MF_NAMESPACES.LISTING].Listing(),
    meta: {
      title: 'Листинг экскурсий по стране',
      destinationType: 'country',
    },
  },
  {
    path: getPath('destinations/:country/:regionSlug'),
    name: 'destinationRegionListing',
    component: () => remotes.value[MF_NAMESPACES.LISTING].Listing(),
    meta: {
      title: 'Листинг экскурсий по региону',
      destinationType: 'region',
    },
  },
  {
    path: getPath('account/guide/:guideId/events/:eventId?/order/'),
    name: 'guideAccountStats',
    component: () => remotes.value[MF_NAMESPACES.GUIDE_ACCOUNT].GuideAccountStats(),
    children: [
      {
        path: ':orderId',
        name: 'guideAccountOrder',
        component: () => remotes.value[MF_NAMESPACES.GUIDE_ACCOUNT_ORDERS].GuideAccountOrder(),
      },
      {
        path: ':orderId/cancel',
        name: 'guideAccountOrderCancel',
        component: () => remotes.value[MF_NAMESPACES.GUIDE_ACCOUNT_ORDERS].GuideAccountOrderCancel(),
      },
      {
        path: ':orderId/cancel-traveller',
        name: 'guideAccountOrderCancelTraveller',
        component: () => remotes.value[MF_NAMESPACES.GUIDE_ACCOUNT_ORDERS].GuideAccountOrderCancelTraveller(),
      },
    ],
  },
  {
    path: getPath('account/guide/:guideId/event/'),
    name: 'guideAccountStatsEvent',
    component: () => remotes.value[MF_NAMESPACES.GUIDE_ACCOUNT].GuideAccountStats(),
    children: [
      {
        path: ':eventId',
        name: 'guideAccountGroupEvent',
        component: () => remotes.value[MF_NAMESPACES.GUIDE_ACCOUNT_ORDERS].GuideAccountGroupEvent(),
      },
    ],
  },
  {
    path: getPath('account/guide/:guideId/'),
    name: 'guideAccount',
    component: () => remotes.value[MF_NAMESPACES.GUIDE_ACCOUNT].GuideAccount(),
    children: [
      {
        path: 'events/',
        name: 'guideAccountOrders',
        component: () => remotes.value[MF_NAMESPACES.GUIDE_ACCOUNT_ORDERS].GuideAccountEvents(),
      },
      {
        path: 'promotion/',
        name: 'guideAccountPromotion',
        component: () => remotes.value[MF_NAMESPACES.GUIDE_ACCOUNT].GuideAccountPromotion(),
      },
      {
        path: 'experiences/',
        name: 'guideAccountExperiences',
        component: () => remotes.value[MF_NAMESPACES.GUIDE_ACCOUNT_EXPERIENCE].GuideAccountExperiences(),
      },
    ],
  },
  {
    path: getPath('new-recovery/:hash?'),
    name: 'recovery',
    component: () => import('@/pages/new-recovery/index.vue'),
  },
  {
    path: '/oauth-callback',
    name: 'oauthCallback',
    component: () => import('@/pages/oauth-callback/index.vue'),
  },
  /**
   * ЛК Партнера - Вкладка "Продажи"
   */
  {
    path: '/affiliate/sales/',
    name: 'affiliate-sales',
    component: () => remotes.value[MF_NAMESPACES.PARTNERS_PERSONAL_ACCOUNT].PartnersPersonalAccountSales(),
  },
  /**
   * ЛК Партнера - Вкладка "Начисления"
   */
  {
    path: '/affiliate/fees/',
    name: 'affiliate-fees',
    component: () => remotes.value[MF_NAMESPACES.PARTNERS_PERSONAL_ACCOUNT].PartnersPersonalAccountFees(),
  },
  /**
   * ЛК Партнера - Вкладка "Платежи"
   */
  {
    path: '/affiliate/payments/',
    name: 'affiliate-payments',
    component: () => remotes.value[MF_NAMESPACES.PARTNERS_PERSONAL_ACCOUNT].PartnersPersonalAccountPayments(),
  },
  /**
   * ЛК Партнера - Вкладка "Детализация"
   */
  {
    path: '/affiliate/orders/',
    name: 'affiliate-orders',
    component: () => remotes.value[MF_NAMESPACES.PARTNERS_PERSONAL_ACCOUNT].PartnersPersonalAccountOrders(),
  },
  /**
   * ЛК Партнера - Вкладка "Рекламные инструменты"
   */
  {
    path: '/affiliate/tools/',
    name: 'affiliate-tools',
    component: () => remotes.value[MF_NAMESPACES.PARTNERS_PERSONAL_ACCOUNT].PartnersPersonalAccountTools(),
  },
  /**
   * ЛК Партнера - Вкладка "Инструкция"
   */
  {
    path: '/affiliate/instruction/',
    name: 'affiliate-instruction',
    component: () => remotes.value[MF_NAMESPACES.PARTNERS_PERSONAL_ACCOUNT].PartnersPersonalAccountInstruction(),
  },
  /**
   * Путешественник - Мои заказы
   */
  {
    path: '/account/traveler',
    name: 'traveler-orders-page',
    component: () => remotes.value[MF_NAMESPACES.TRAVELER_ORDERS].TravelerOrdersPage(),
    redirect: { name: 'traveler-orders' },
    children: [
      {
        path: 'inbox',
        name: 'traveler-orders',
        component: () => remotes.value[MF_NAMESPACES.TRAVELER_ORDERS].TravelerOrdersOrdersPage(),
        meta: {
          title: 'Мои заказы',
        },
      },
      {
        path: 'bonuses',
        name: 'traveler-bonuses',
        component: () => remotes.value[MF_NAMESPACES.TRAVELER_ORDERS].TravelerOrdersBonusesPage(),
        meta: {
          title: 'Промокоды',
        },
      },
    ],
  },
  {
    path: getPath('experience/order/:orderId'),
    name: 'traveler-order',
    component: () => remotes.value[MF_NAMESPACES.TRAVELER_ORDER].TravelerOrderPage(),
  },
  {
    path: '/journal',
    name: 'journal',
    component: () => remotes.value[MF_NAMESPACES.JOURNAL].JournalPage(),
  },
  {
    path: '/journal/author/:id',
    name: 'journal-author',
    component: () => remotes.value[MF_NAMESPACES.JOURNAL].JournalPage(),
  },
  {
    path: getPath('example'),
    name: 'example',
    component: () => remotes.value[MF_NAMESPACES.TEST_APP].Example(),
  },
  /**
   * Путешественник - избранное
   */
  {
    path: '/favorites',
    name: 'traveler-favorites-page',
    // component: () => remotes.value[MF_NAMESPACES.TRAVELER_FAVORITES].TravelerFavoritesPage(),
    component: () => import('../pages/traveler-favorites/index.vue'),
    meta: {
      title: 'Избранное',
    },
  },
  {
    path: getPath('traveler-favorites-skelet'),
    name: 'traveler-favorites-page-skelet',
    component: () => remotes.value[MF_NAMESPACES.TRAVELER_FAVORITES].TravelerFavoritesPage(),
    meta: {
      title: 'Избранное',
    },
  },

  {
    path: '/tours/:countySlug/category/:categorySlug',
    name: 'seo-category-listing',
    component: () => remotes.value[MF_NAMESPACES.SEO_CATEGORY_LISTING].SeoCategoryListing(),
    props: true,
  },
  /**
   * Партнерский лендинг
   */
  {
    path: '/partners',
    name: 'partners',
    component: () => remotes.value[MF_NAMESPACES.PARTNERS_LANDING].PartnersLanding(),
  },
  {
    path: '/partners/travelagents',
    name: 'partners-travelagents',
    component: () => remotes.value[MF_NAMESPACES.PARTNERS_LANDING].TravelAgentsLanding(),
  },
  {
    path: '/tags-moderation',
    name: 'tags-moderation',
    component: () => remotes.value[MF_NAMESPACES.TAGS_MODERATION].TagsModeration(),
  },
  {
    path: '/experience/sights/:slug/',
    name: 'sights',
    component: () => remotes.value[MF_NAMESPACES.SIGHTS].SightsPage(),
  },
];
