<template>
  <nav class="footer-socials">
    <a
      v-for="(item, index) in FOOTER_SOCIAL_LINKS"
      :key="index"
      :href="item.link"
      rel="noreferrer"
      target="_blank"
      @click.stop="sendSoguSocialClickEvent(item.social_type, 'footer')"
    >
      <component
        :is="item.icon"
        class="icon"
        :width="props.size"
        :height="props.size"
      />
    </a>
  </nav>
</template>

<script setup lang="ts">
  import { FOOTER_SOCIAL_LINKS } from './сonsts';

  interface Props {
    size: string;
  }

  const props = withDefaults(defineProps<Props>(), {
    size: '56',
  });

  const sendSoguSocialClickEvent = (item: string, type: 'footer') => {
    // TODO: add sogu event
    console.log('sendSoguSocialClickEvent: ', item, type);
  };
</script>

<style lang="scss" scoped>
  .footer-socials {
    display: flex;
    height: 56px;
    column-gap: 8px;

    :deep(.icon) {
      color: var(--alias-color-icon-default);
      fill: var(--alias-color-icon-default);

      &:hover {
        color: var(--alias-color-link-default-hovered);
        fill: var(--alias-color-link-default-hovered);
      }
    }
  }
</style>
