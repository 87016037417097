import type { Experiment } from '@/shared/api/bootstrap/types.gen';

/**
 * Устанавливает куки для каждого A/B эксперимента.
 * Кука имеет формат: tripster-experiment-experimentName=experimentName.
 *
 * @param experiments - Список экспериментов
 */
export const setExperimentCookies = (experiments: Experiment[]): void => {
  if (!Array.isArray(experiments) || experiments.length === 0) {
    console.warn('setExperimentCookies: Некорректные или пустые данные об экспериментах');

    return;
  }

  const experimentNames = new Set(experiments.map(({ name }) => name).filter(Boolean));

  // Получаем все куки
  const cookies = document.cookie.split('; ');

  cookies.forEach((cookie) => {
    const [cookieName] = cookie.split('=');

    // Удаление старых куков без префикса, но с совпадающим именем эксперимента
    if (experimentNames.has(cookieName) && !cookieName.startsWith('tripster-experiment-')) {
      document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;

      return;
    }

    // Удаление устаревших tripster-experiment-* куков
    if (cookieName.startsWith('tripster-experiment-')) {
      const experimentName = cookieName.replace('tripster-experiment-', '');
      if (!experimentNames.has(experimentName)) {
        document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
      }
    }
  });

  // Установка/обновление актуальных куков
  experiments.forEach(({ name }) => {
    if (name) {
      document.cookie = `tripster-experiment-${name}=${name}; path=/; SameSite=Lax;`;
    }
  });
};
