import type { App } from 'vue';

import { appPluginsOrder } from '@/config/app';

type Plugins = Record<string, { install: (app: ReturnType<typeof createApp>) => void }>;

const sortByOrder = (values: string[], order: string[]): string[] => {
  const orderDict: Record<string, number> = {};

  order.forEach((key, index) => {
    orderDict[key] = index;
  });

  const sortKey = (val: string): number => {
    return orderDict[val] !== undefined ? orderDict[val] : order.length;
  };

  return values.sort((a, b) => sortKey(a) - sortKey(b));
};

const normalizePathPlugin = (plugins: Plugins) => {
  const result: Plugins = {};

  Object.entries(plugins).forEach(([pluginPath, module]) => {
    const pluginName = pluginPath.replace('./', '').replace('/index.ts', '');
    result[pluginName] = module;
  });

  return result;
};

export const installVuePlugins = (app: App) => {
  const plugins = import.meta.glob('./*/index.ts', { eager: true }) as Plugins;
  const pluginsNormalized = normalizePathPlugin(plugins);

  const pluginNames = Object.keys(pluginsNormalized);

  sortByOrder(pluginNames, appPluginsOrder).forEach((plugin) => {
    pluginsNormalized[plugin].install(app);
  });
};
