<template>
  <LayoutProvider>
    <RouterView />
  </LayoutProvider>
</template>

<script setup lang="ts">
  import { getMFRenderInfo } from '@experience/core-hostapp-share-lib';
  import { useHead } from '@unhead/vue';
  import { onMounted } from 'vue';
  import { RouterView, useRoute } from 'vue-router';

  import { logPerformanceMetrics } from '@/shared/lib';

  import { LayoutProvider } from './layouts';

  const route = useRoute();
  const routeTitle = computed(() => route.meta.title as string);
  useHead({
    title: () => routeTitle.value,
    meta: [
      {
        name: 'description',
        content: 'Tripster — бронируйте авторские экскурсии по всему миру.',
      },
    ],
  });

  logPerformanceMetrics();

  onMounted(async () => {
    // Проверяем, загрузился ли mf
    const { mfRendered, name, timestamp } = await getMFRenderInfo();
    if (mfRendered) {
      // Выводим лог performance mf в ms
      console.log(`MF "${name}" загружен! Время рендера: ${timestamp}`);
    } else {
      console.log('MF еще не загрузился!');
    }
  });
</script>

<style scoped></style>
