export const logPerformanceMetrics = () => {
  // 1. DOMContentLoaded
  const [navigationEntry] = performance.getEntriesByType('navigation') as PerformanceNavigationTiming[];
  if (navigationEntry) {
    const domContentLoaded = navigationEntry.domContentLoadedEventEnd - navigationEntry.startTime;
    console.log(`DOMContentLoaded (DevTools): ${domContentLoaded.toFixed(2)} ms`);
  }

  // 2. LCP (Largest Contentful Paint)
  const lcpObserver = new PerformanceObserver((list) => {
    const [lcpEntry] = list.getEntries();
    if (lcpEntry) {
      console.log(`LCP (Largest Contentful Paint): ${lcpEntry.startTime.toFixed(2)} ms`);
    }
  });
  lcpObserver.observe({ type: 'largest-contentful-paint', buffered: true });

  // 3. Host-app rendering self & total time (Vue рендер)
  performance.mark('host-app-render-start');
  onMounted(() => {
    performance.mark('host-app-render-end');
    performance.measure('HostAppRenderTimeSelf', 'host-app-render-start', 'host-app-render-end');
    performance.measure('HostAppRenderTimeTotal', 'host-app-script-start', 'host-app-render-end');
    const measureSelf = performance.getEntriesByName('HostAppRenderTimeSelf')[0];
    console.log(`Host-app rendering completed (self): ${measureSelf.duration.toFixed(2)} ms`);
    const measureTotal = performance.getEntriesByName('HostAppRenderTimeTotal')[0];
    console.log(`Host-app rendering completed (total): ${measureTotal.duration.toFixed(2)} ms`);
  });
};
