<template>
  <div
    v-if="showAcceptCookiesBlock"
    class="cookies"
  >
    <div class="cookies__text">
      Используя сайт, вы&nbsp;соглашаетесь
      <a
        class="blue-link"
        href="/about/privacy_policy/#cookies"
        target="_blank"
      >
        с&nbsp;политикой cookie
      </a>
    </div>
    <div
      class="cookies__close"
      @click="closeAcceptCookiesBlock"
    >
      Ok
    </div>
  </div>
</template>
<script setup lang="ts">
  import dayjs from 'dayjs';
  import Cookies from 'universal-cookie';

  import { useScreenSize } from '@/shared/lib';

  import { CookieOptions } from './consts';

  const { isMobile } = useScreenSize();
  const route = useRoute();

  const showAcceptCookiesBlock = ref<boolean>(false);

  const bottomposition = computed<string>(() => {
    if (isMobile.value) {
      return '12px';
    }

    return '24px';
  });
  const cookies = new Cookies();

  const setGDPRCookie = () => {
    cookies.set(CookieOptions.GDPRCookieName, CookieOptions.currentPolicyVersion, {
      expires: dayjs().add(1, 'year').toDate(),
    });
  };

  const isCookieHadSet = () => {
    return String(cookies.get(CookieOptions.GDPRCookieName)) === CookieOptions.currentPolicyVersion;
  };

  const closeAcceptCookiesBlock = () => {
    setGDPRCookie();
    showAcceptCookiesBlock.value = false;
  };

  onMounted(() => {
    showAcceptCookiesBlock.value = !isCookieHadSet();
  });

  watch(
    () => route,
    (newVal, oldVal) => {
      if (oldVal.name) {
        closeAcceptCookiesBlock();
      }
    },
  );
</script>

<style scoped lang="scss">
  @use '@/styles/global/mixins';

  .cookies {
    position: fixed;
    bottom: v-bind(bottomposition);
    left: 24px;
    z-index: 1000;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 412px;
    padding: 12px 12px 12px 16px;
    border-radius: 8px;
    background: #fff;
    box-shadow:
      0 0 2px rgba(0, 0, 0, 0.08),
      0 8px 24px rgba(0, 0, 0, 0.08);
    font-family: var(--ff);

    @include mixins.mobile {
      right: 15px;
      bottom: v-bind(bottomposition);
      left: 15px;
      width: auto;
      padding: 8px 8px 8px 12px;
    }
  }

  .cookies__text {
    font-size: 18px;
    line-height: 24px;

    a {
      text-decoration: underline;
    }

    @include mixins.mobile {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .cookies__close {
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 72px;
    margin: 0 0 0 16px;
    padding: 12px 0;
    border: 1px solid #bbbcbf;
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    cursor: pointer;

    @include mixins.mobile {
      width: 45px;
      height: 36px;
      margin: 0 0 0 auto;
      padding: 0;
      font-size: 14px;
    }
  }
</style>
