import type { DirectiveBinding, ObjectDirective } from 'vue';

const updateElementStyle = (el: HTMLElement, binding: DirectiveBinding<boolean>) => {
  el.style.zIndex = binding.value ? '10002' : '';
};
/**
 * Директива, которая опционально поднимает над оверлеем элемент, на который она накинута. Пример использования:
 * .some-element(v-lift="someExpression")
 * Если someExpression будет эквивалентно true, то элемент будет над оверлеем, в противном случае - под ним.
 */
export const liftDirective: ObjectDirective<HTMLElement, boolean> = {
  mounted(el, binding) {
    updateElementStyle(el, binding);
  },
  updated(el, binding) {
    updateElementStyle(el, binding);
  },
};
