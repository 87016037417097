<template>
  <section class="layout-default">
    <partners-banners :init-user="formattedUser" />

    <SiteHeader
      v-if="!isPartnerTravelAgentsLanding"
      :init-user="formattedUser"
    />
    <div class="main-container">
      <slot />
    </div>

    <!-- Отображаем footer после загрузки mf -->
    <SiteFooter v-if="isMFRendered && !isPartnerTravelAgentsLanding" />
    <!--____________________-->

    <div id="notifications">
      <GDPRCookieConfirm />
    </div>
  </section>
</template>
<script lang="ts" setup>
  import { getMFRenderInfo } from '@experience/core-hostapp-share-lib';
  import { SiteHeader } from '@experience/site-header-library';
  import { PartnersBanners } from '@experience/partners-banners-lib';
  import { onMounted } from 'vue';
  import { useRoute } from 'vue-router';

  import GDPRCookieConfirm from '@/shared/ui/GDPRCookieConfirm/GDPRCookieConfirm.vue';

  import { useUserStore } from '@/entities/user';

  import { SiteFooter } from '@/widgets/layout/default';

  const { user } = useUserStore();

  const formattedUser = computed(() => ({
    user: user?.user ?? null,
    features: user?.features ?? null,
  }));

  const isMFRendered = ref(false);

  const route = useRoute();
  const isPartnerTravelAgentsLanding = computed(() => route.path.includes('/partners/travelagents'));

  // Ждем загрузки mf и делаем рендер футера или рендер через 2 сек
  const waitForMFRender = (): void => {
    let timeoutId: ReturnType<typeof setTimeout> | null = setTimeout(() => {
      isMFRendered.value = true;
      timeoutId = null;
    }, 2000);

    getMFRenderInfo()
      .then(({ mfRendered }) => {
        if (mfRendered) {
          isMFRendered.value = true;
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
        }
      })
      .catch(() => {});
  };

  onMounted(() => {
    waitForMFRender();
  });
</script>

<style lang="scss">
  html,
  body {
    margin: 0;
    padding: 0;
  }
</style>
<style scoped lang="scss">
  .layout-default {
    display: flex;
    flex-direction: column;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media screen and (min-width: 1024px) {
      min-height: calc(100vh - 720px);
    }
  }
</style>
