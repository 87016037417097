<template>
  <div>
    <button
      class="qr-btn"
      @click="openQRModal"
    >
      <img
        src="@/assets/sprites/qr-modal-btn.svg?url"
        width="24"
        height="24"
        alt="Скачайте приложение&nbsp;Tripster для&nbsp;путешественников"
        loading="lazy"
      />
      <!-- <QrModalButton /> -->
    </button>
    <ui-modal
      :open="isShowQRModal"
      size-modal="xs"
      @close="closeQRModal"
    >
      <template #content>
        <div class="qr-modal">
          <QRCodeIcon color="var(--alias-color-icon-default)" />

          <h3 class="qr-modal__title">Приложение Tripster</h3>
          <div class="qr-modal__text">
            Наведите камеру на QR-код, <br />
            чтобы установить приложение
          </div>
        </div>
      </template>
    </ui-modal>
  </div>
</template>

<script setup lang="ts">
  import { UiModal } from '@experience/vue3-kit';

  import QRCodeIcon from '@/shared/ui/icons/QRCodeIcon.vue';

  const isShowQRModal = ref<boolean>(false);

  const openQRModal = () => {
    isShowQRModal.value = true;
  };

  const closeQRModal = () => {
    isShowQRModal.value = false;
  };
</script>

<style scoped lang="scss">
  @use '@/styles/global/mixins';

  .qr-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 8px;
    border: 1px solid var(--comp-button-outline-color-border-neutral);
    border-radius: 8px;
    background: none;

    &:hover {
      border-color: var(--comp-button-outline-color-border-neutral-hovered);
      cursor: pointer;
    }

    @include mixins.mobile {
      display: none;
    }
  }

  .qr-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 88px;
    margin-bottom: 32px;
  }

  .qr-modal__title {
    margin-top: 24px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  .qr-modal__text {
    margin-top: 8px;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
  }
</style>
