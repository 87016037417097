<template>
  <div class="footer-contacts">
    <div class="footer-contacts__title">
      Поддержка
      <span class="footer-contacts__time"> 9...21 МСК </span>
    </div>
    <div class="footer-contacts__list">
      <a
        v-for="item of FOOTER_CONTACTS"
        :key="item.id"
        :href="item.href"
        :rel="item.rel"
        target="_blank"
        data-gtmtrigger="LinkClick"
        data-eventcategory="site_contacts"
        data-eventaction="footer"
        :data-eventlabel="item.eventLabel"
        data-eventnoninteraction="1"
        class="footer-contacts__item"
        @click="sendSoguContactsClickEvent(item.type)"
      >
        <component
          :is="item.icon"
          v-if="item.icon"
          :height="item.height"
          :width="item.width"
          class="footer-contacts__flag"
        />
        {{ item.title }}
      </a>
    </div>
    <button
      class="footer-contacts__feedback"
      @click="sendFeedback"
    >
      Написать <br />
      отзыв о сайте

      <arrow-top-right-icon class="footer-contacts__arrow" />
    </button>
  </div>
</template>

<script setup lang="ts">
  import { useScreenSize } from '@/shared/lib';
  import { ArrowTopRightIcon } from '@/shared/ui/icons';

  import { FOOTER_CONTACTS } from './сonsts';

  const { isDesktop } = useScreenSize();

  const sendFeedback = () => {
    const eventName = isDesktop.value ? 'buttonClickWeb' : 'buttonClickMob';
    window?.UXS?.sendEvent(eventName);
  };

  const sendSoguContactsClickEvent = (value: string) => {
    // TODO: add sogu event
    console.log('sendSoguContactsClickEvent ', value);
  };
</script>

<style scoped lang="scss">
  @use '@/styles/global/mixins';

  .footer-contacts {
    display: flex;
    flex: 3;
    flex-direction: column;
    max-width: 292px;

    @include mixins.tablet {
      flex: 4;
      max-width: 100%;
    }
  }

  .footer-contacts__title,
  .footer-contacts__time {
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
  }

  .footer-contacts__time {
    margin-left: 4px;
    color: var(--alias-color-text-subtle);
  }

  .footer-contacts__list {
    display: grid;
    row-gap: 16px;
    margin-top: 16px;
  }

  .footer-contacts__item {
    display: flex;
    align-items: center;
    width: fit-content;
    color: var(--alias-color-link-darked);
    font-size: 17px;
    line-height: 24px;
    text-decoration: none;

    &:hover {
      color: var(--alias-color-link-default-hovered);
    }
  }

  .footer-contacts__flag {
    margin-right: 6px;
  }

  .footer-contacts__feedback {
    position: relative;
    width: 100%;
    height: 88px;
    margin-top: auto;
    padding: 32px 16px 16px;
    border: 1px solid var(--comp-button-outline-color-border-neutral);
    border-radius: 8px;
    background: none;
    color: var(--comp-button-outline-color-text);
    font-size: 17px;
    font-family: var(--ff);
    line-height: 20px;
    text-align: left;

    &:hover {
      border-color: var(--comp-button-outline-color-border-neutral-hovered);
      cursor: pointer;
    }

    @include mixins.tablet {
      width: 227px;
    }

    @include mixins.mobile {
      width: 100%;
      margin-top: 24px;
    }
  }

  .footer-contacts__arrow {
    position: absolute;
    top: 12px;
    right: 12px;
  }
</style>
