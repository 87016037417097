import type { App } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import { routes } from '@/router';

import { layout } from './guards';
import { listingRedirect } from './redirects';

const guards = [layout.guard];
const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
      };
    }

    if (savedPosition) {
      return savedPosition; // Восстанавливаем позицию скролла при навигации назад/вперед
    } else {
      return false; // Отключаем автоматический скролл
    }
  },
});

export const install = (app: App) => {
  router.beforeEach(async (to, from, next) => {
    try {
      if (listingRedirect.handleRedirect(to, next)) {
        return;
      }

      for await (const guard of guards) {
        /**
         * Если из guard-а вернулось значение, выполняем редирект.
         * Важно: если guard вернул значение, то необходимо выполнить return,
         * чтобы прервать цикл.
         */
        try {
          const redirect = await guard(to, from, next);

          if (redirect) {
            next(redirect);

            return;
          }
        } catch (error) {
          console.warn({ guard, error });
          next('/');

          return;
        }
      }

      next();
    } catch (e) {
      //
      console.log(e);
    }
  });
  app.use(router);
};
