import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

const formatSlugs = (params: Record<string, string | string[]>): Record<string, string> => {
  const formattedParams: Record<string, string> = {};

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      const [firstValue] = value; // Деструктуризация массива, чтобы извлечь первый элемент
      formattedParams[key] = firstValue; // Присваиваем первый элемент массива
    } else if (typeof value === 'string') {
      formattedParams[key] = value;
    }
  });

  // Приводим citySlug к заглавной букве, а countrySlug и regionSlug — к строчным
  if (formattedParams.citySlug && formattedParams.citySlug[0] !== formattedParams.citySlug[0].toUpperCase()) {
    formattedParams.citySlug = formattedParams.citySlug[0].toUpperCase() + formattedParams.citySlug.slice(1);
  }
  if (formattedParams.countrySlug) {
    formattedParams.countrySlug = formattedParams.countrySlug.toLowerCase();
  }
  if (formattedParams.country) {
    formattedParams.country = formattedParams.country.toLowerCase();
  }
  if (formattedParams.regionSlug) {
    formattedParams.regionSlug = formattedParams.regionSlug.toLowerCase();
  }

  return formattedParams;
};

export const handleRedirect = (to: RouteLocationNormalized, next: NavigationGuardNext): boolean => {
  const formattedParams = formatSlugs({ ...to.params });

  // Если параметры изменились, редиректим на новый путь
  const newPath = to.path
    .replace(String(to.params.citySlug), formattedParams.citySlug)
    .replace(String(to.params.countrySlug), formattedParams.countrySlug)
    .replace(String(to.params.regionSlug), formattedParams.regionSlug)
    .replace(String(to.params.country), formattedParams.country);

  // Проверяем, изменился ли путь, и выполняем редирект
  if (newPath !== to.path) {
    next({
      path: newPath,
      replace: true,
    });

    return true; // Редирект был выполнен, прерываем дальнейшую обработку
  }

  return false; // Нет редиректа
};
