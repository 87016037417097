import { useMediaQuery } from '@vueuse/core';

const MediaQueries = {
  IS_MOBILE: '(max-width: 767px)',
  IS_TABLET: '(max-width: 1023px)',
  IS_DESKTOP: '(min-width: 1024px)',
} as const;

const isMobile = computed(() => useMediaQuery(MediaQueries.IS_MOBILE).value);
const isTablet = computed(() => useMediaQuery(MediaQueries.IS_TABLET).value);
const isDesktop = computed(() => useMediaQuery(MediaQueries.IS_DESKTOP).value);

export const useScreenSize = () => {
  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};
