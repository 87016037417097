import type { App } from 'vue';

const GTM_ID = 'GTM-MZRPDJ';
const YANDEX_METRIKA_ID = 19704109;

/**
 * Функция для загрузки Google Tag Manager
 */
function loadGoogleTagManager() {
  if (document.getElementById('gtm-script')) {
    return;
  }

  const script = document.createElement('script');
  script.id = 'gtm-script';
  script.async = true;
  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${GTM_ID}');
  `;

  document.head.appendChild(script);
}

/**
 * Функция для загрузки Yandex Metrika
 */
function loadYandexMetrika() {
  if (document.getElementById('ym-script')) {
    return;
  }

  const script = document.createElement('script');
  script.id = 'ym-script';
  script.async = true;
  script.src = 'https://mc.yandex.ru/metrika/tag.js';

  script.onload = () => {
    // @ts-expect-error window.ym is not defined in TypeScript type definitions
    if (window.ym) {
      // @ts-expect-error window.ym is not defined in TypeScript type definitionss
      window.ym(YANDEX_METRIKA_ID, 'init', {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: false,
        ecommerce: 'dataLayer',
      });
    }
  };

  document.head.appendChild(script);
}

/**
 * Метод для динамической загрузки аналитики
 */
export function loadAnalytics() {
  if ('requestIdleCallback' in window) {
    requestIdleCallback(() => {
      loadGoogleTagManager();
      loadYandexMetrika();
    });
  } else {
    setTimeout(() => {
      loadGoogleTagManager();
      loadYandexMetrika();
    }, 50);
  }
}

/**
 * Vue Plugin для аналитики
 */
export const install = (app: App) => {
  app.config.globalProperties.$loadAnalytics = loadAnalytics;

  // Загружаем аналитику после рендера MF
  setTimeout(() => {
    loadAnalytics();
  }, 3000);
};
