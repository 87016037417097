<template>
  <aside class="footer-menus">
    <div
      v-for="menu in FOOTER_MENUS"
      :key="menu.id"
      @click="faqButtonClickEvent($event)"
    >
      <div
        class="footer-menus__title"
        :class="{ active: accordion[menu.type] }"
        @click="toggleAccordion(menu.type)"
      >
        {{ menu.title }}

        <ArrowMoreIcon />
      </div>
      <menu
        class="footer-menu"
        :class="{ active: accordion[menu.type] }"
      >
        <a
          v-for="(menuItem, id) in menu.items"
          :key="id"
          :href="menuItem.link"
          class="footer-menu__item"
        >
          {{ menuItem.text }}
        </a>
      </menu>
    </div>
  </aside>
</template>

<script setup lang="ts">
  import ArrowMoreIcon from '@/shared/ui/icons/ArrowMoreIcon.vue';

  import { FOOTER_MENUS } from './сonsts';

  const accordion = reactive<Record<string, boolean>>({
    guide: false,
    partner: false,
    traveler: false,
  });

  const faqButtonClickEvent = (e: Event) => {
    // TODO: add sogu event
    console.log('sogu event: ', e);
  };
  const toggleAccordion = (key: string) => {
    accordion[key] = !accordion[key];
  };
</script>

<style scoped lang="scss">
  @use '@/styles/global/mixins';

  .footer-menus {
    display: flex;
    flex: 3;
    flex-direction: column;
    row-gap: 32px;
    max-width: 292px;

    @include mixins.mobile {
      row-gap: 24px;
      max-width: 100%;
    }
  }

  .footer-menus__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;

    svg {
      display: none;
      color: var(--alias-color-icon-default);
      transition: all 0.2s;

      @include mixins.mobile {
        display: block;
      }
    }

    &.active {
      svg {
        transform: rotate(180deg);
      }
    }

    @include mixins.mobile {
      cursor: pointer;
    }
  }

  .footer-menu {
    display: grid;
    row-gap: 16px;
    margin-top: 16px;

    &.active {
      display: grid;
    }

    @include mixins.mobile {
      display: none;
    }
  }

  .footer-menu__item {
    width: fit-content;
    color: var(--alias-color-link-darked);
    font-size: 17px;
    line-height: 24px;
    text-decoration: none;

    &:hover {
      color: var(--alias-color-link-default-hovered);
    }

    @include mixins.tablet {
      width: 95%;
    }

    @include mixins.mobile {
      width: fit-content;
    }
  }
</style>
