import type { LayoutsMap } from '../types';
import * as Layouts from '../ui';

const DEFAULT_LAYOUT = 'Default';

export const useLayout = defineStore('layout', () => {
  const layouts = Layouts;
  const currentLayout = ref<LayoutsMap | null>(null);

  const selectLayout = (layout: LayoutsMap | undefined) => {
    if (!layout) {
      currentLayout.value = DEFAULT_LAYOUT;

      return;
    }

    if (!layouts[layout]) {
      currentLayout.value = DEFAULT_LAYOUT;

      return;
    }

    currentLayout.value = layout;
  };

  const currentLayoutComponent = computed(() => {
    if (!currentLayout.value) {
      return null;
    }

    return layouts[currentLayout.value];
  });

  return {
    selectLayout,
    currentLayoutComponent,
  };
});
