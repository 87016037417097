import { setBootstrapData } from '@experience/core-hostapp-share-lib';
import { createApp } from 'vue';

import type { GetBootstrapResponse } from '@/shared/api/bootstrap/types.gen';
import { getIsWhitelabel } from '@/shared/lib/getIsWhitelabel';

import { useUserStore } from '@/entities/user/store';

import { setExperimentCookies } from '@/features/experiments/lib/setExperimentCookies';
import { setDeviceIdCookie } from '@/features/session/lib/setDeviceIdCookie';

import { installVuePlugins } from './app/plugins';
import App from './App.vue';
import { liftDirective } from './directives';
import { useRemoteApps } from './useRemoteApps';
/*
|--------------------------------------------------------------------------
| Import base styles
|--------------------------------------------------------------------------
*/
import '@experience/design-tokens/color.css';
import '@experience/design-tokens/dimension.css';

/*
|--------------------------------------------------------------------------
| Set User Session
|--------------------------------------------------------------------------
*/
let userSession: GetBootstrapResponse | null = null;
try {
  const response = await window.__USER_SESSION_PROMISE__;
  if (response?.ok) {
    userSession = await response.json();
  }
} catch (error) {
  console.error('Ошибка запроса bootstrap:', error);
}

/*
|--------------------------------------------------------------------------
| Set Analytics pnpm data
|--------------------------------------------------------------------------
*/
setBootstrapData({
  user_id: userSession?.user?.id || null,
  userId: userSession?.user?.id || null,
  device_id: userSession?.session?.device_id || null,
  deviceId: userSession?.session?.device_id || null,
  isAuthenticated: Boolean(userSession?.user?.is_authenticated),
  isPartner: Boolean(userSession?.user?.is_partner),
  isUnderImpersonalization: userSession?.features?.under_impersonalization ?? false,
  recaptcha: {
    isEnabled: userSession?.features?.recaptcha?.is_enabled ?? false,
    v3Secret: userSession?.features?.recaptcha?.v3_secret ?? '',
  },
  firstName: userSession?.user?.first_name ?? '',
  lastName: userSession?.user?.last_name ?? '',
  email: userSession?.user?.email ?? '',
  userAvatar: userSession?.user?.avatar ?? '',
  isWhitelabel: getIsWhitelabel(),
});

/*
|--------------------------------------------------------------------------
| Set device_id cookie
|--------------------------------------------------------------------------
*/
setDeviceIdCookie(userSession?.session?.device_id);

/*
|--------------------------------------------------------------------------
| Set experiment cookies
|--------------------------------------------------------------------------
*/
if (userSession?.experiments) {
  setExperimentCookies(userSession.experiments);
}
/*
|--------------------------------------------------------------------------
| Create and init app
|--------------------------------------------------------------------------
*/
const { setupRemotes } = useRemoteApps();
await setupRemotes();

const app = createApp(App);
installVuePlugins(app);
app.directive('lift', liftDirective);

// Передача пользователя в store
const userStore = useUserStore();
userStore.setUser(userSession || {});

// Удаляем глобальные переменные из window
delete window.__USER_SESSION_PROMISE__;
// ___________________________________________

app.mount('#app');

// Хак для фикса вариативного шрифта на ios 16.0-16.3
const { userAgent } = navigator;

if (/iPhone|iPad|iPod/i.test(userAgent) && userAgent.match(/OS\s16[_.](0|1|2|3)/)) {
  document.body.classList.add('isIOS16_0-3');
}
