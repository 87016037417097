<template>
  <div class="menu">
    <a
      v-for="item of FOOTER_COMMON_INFO_MENU"
      :key="item.id"
      :href="item.link"
      :target="item.target && isDesktop ? item.target : ''"
      class="menu__item"
    >
      {{ item.text }}
    </a>
  </div>
</template>
<script setup lang="ts">
  import { useScreenSize } from '@/shared/lib';

  import { FOOTER_COMMON_INFO_MENU } from './сonsts';

  const { isDesktop } = useScreenSize();
</script>

<style scoped lang="scss">
  @use '@/styles/global/mixins';

  .menu {
    display: grid;
    row-gap: 6px;
    margin-top: auto;

    @include mixins.mobile {
      margin-top: 40px;
    }
  }

  .menu__item {
    width: fit-content;
    color: var(--alias-color-link-darked);
    font-size: 17px;
    line-height: 24px;
    text-decoration: none;

    &:hover {
      color: var(--alias-color-link-default-hovered);
    }
  }
</style>
