import type { BootstrapResponse } from '@/shared/api/bootstrap/types.gen';

export const useUserStore = defineStore('user store', () => {
  const user = ref<Nullable<BootstrapResponse>>(null);

  const setUser = (userData: BootstrapResponse | undefined) => {
    user.value = userData || null;
  };

  return {
    user,
    setUser,
  };
});
