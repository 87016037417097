import { RussianFlagIcon, EuFlagIcon } from '@/shared/ui/icons/flags';
import { TelegramIcon, VkIcon, DzenIcon, OdnoklassnikiIcon } from '@/shared/ui/icons/socials';

export const FOOTER_MENUS = [
  {
    items: [
      {
        active: true,
        link: '/help_center/travelers/',
        text: 'Частые вопросы',
      },
      {
        active: true,
        link: '/about/terms/',
        text: 'Условия использования',
      },
      {
        active: true,
        link: '/about/privacy_policy/',
        text: 'Политика конфиден­ци­аль­ности',
      },
      {
        active: true,
        link: '/about/cancellation_policy/',
        text: 'Политика возврата',
      },
      {
        active: true,
        link: '/about/secure_payments/',
        text: 'Безопасность платежей',
      },
    ],
    title: 'Путешественникам',
    type: 'traveler',
    id: 1,
  },
  {
    items: [
      {
        link: '/locals/',
        text: 'Стать гидом',
      },
      {
        link: '/help_center/guides/',
        text: 'Частые вопросы',
      },
      {
        link: '/help_center/guides/rules/',
        text: 'Правила работы',
      },
    ],
    title: 'Гидам',
    type: 'guide',
    id: 2,
  },
  {
    items: [
      {
        link: '/partners/',
        text: 'Стать партнёром',
      },
      {
        link: '/affiliate/',
        text: 'Кабинет партнёра',
      },
      {
        link: 'https://tripster.atlassian.net/wiki/spaces/affiliates/overview',
        text: 'Документация',
      },
    ],
    title: 'Партнерам',
    type: 'partner',
    id: 3,
  },
];

export const FOOTER_COMMON_INFO_MENU = [
  {
    id: 1,
    link: '/',
    text: 'Экскурсии',
  },
  {
    id: 2,
    link: '/tours/',
    text: 'Туры',
  },
  {
    id: 3,
    link: '/journal/',
    text: 'Журнал Трипстера',
  },
  {
    id: 4,
    link: 'https://tripster.huntflow.io/',
    text: 'Вакансии',
  },
  {
    id: 5,
    link: '/about/',
    text: 'О проекте',
  },
];

export const FOOTER_PAY_SYSTEMS = [
  {
    id: 1,
    img: new URL(`@/assets/sprites/visa-pay.svg`, import.meta.url).href,
    width: '31.6',
    height: '10',
    alt: 'VISA',
  },
  {
    id: 2,
    img: new URL('@/assets/sprites/masterCard-pay.svg', import.meta.url).href,
    width: '25.6',
    height: '16',
    alt: 'MasterCard',
  },
  {
    id: 3,
    img: new URL('@/assets/sprites/mir-pay.svg', import.meta.url).href,
    width: '35',
    height: '10',
    alt: 'МИР',
  },
  {
    id: 4,
    img: new URL('@/assets/sprites/maestro-pay.svg', import.meta.url).href,
    width: '25.6',
    height: '16',
    alt: 'Maestro',
  },
  {
    id: 5,
    img: new URL('@/assets/sprites/union-pay.svg', import.meta.url).href,
    width: '21',
    height: '14',
    alt: 'Union Pay',
  },
  {
    id: 6,
    img: new URL('@/assets/sprites/americanExpress-pay.svg', import.meta.url).href,
    width: '21',
    height: '14',
    alt: 'American Express',
  },
  {
    id: 7,
    img: new URL('@/assets/sprites/jcb-pay.svg', import.meta.url).href,
    width: '24',
    height: '24',
    alt: 'JCB',
  },
];

export const FOOTER_CONTACTS = [
  {
    id: 1,
    eventLabel: 'tel',
    href: 'https://t.me/tripster_help_bot',

    rel: 'noreferrer nofollow',
    title: 'Telegram',
    type: 'telegram',
  },
  {
    id: 2,
    eventLabel: 'vk',
    href: 'https://vk.com/im?sel=-51519887',
    rel: 'noreferrer nofollow',
    title: 'VKontakte',
    type: 'vk',
  },
  {
    id: 3,
    eventLabel: 'ws',
    href: 'https://api.whatsapp.com/send?phone=79916994447',
    rel: 'noreferrer nofollow',
    title: 'WhatsApp',
    type: 'whatsapp',
  },
  {
    id: 4,
    eventLabel: 'header_email',
    href: 'mailto:support@tripster.ru',
    rel: 'noreferrer',
    title: 'support@tripster.ru',
    type: 'email',
  },
  {
    id: 5,
    code: 'ru',
    eventLabel: 'header_tel',
    href: 'tel:+74951463966',
    rel: 'noreferrer',
    title: '+7 495 146-39-66',
    type: 'phone',
    icon: RussianFlagIcon,
    height: '20',
    width: '20',
  },
  {
    id: 6,
    code: 'eu',
    eventLabel: 'header_tel',
    href: 'tel:+420228889544',
    rel: 'noreferrer',
    title: '+420 228 889 544',
    type: 'phone',
    icon: EuFlagIcon,
    height: '20',
    width: '20',
  },
];
export const FOOTER_SOCIAL_LINKS = [
  {
    icon: TelegramIcon,
    link: 'https://t.me/tripsterofficial',
    social_type: 'telegram',
  },
  {
    icon: VkIcon,
    link: 'https://vk.com/tripster_ru',
    social_type: 'vk',
  },
  {
    icon: DzenIcon,
    link: 'https://dzen.ru/id/5dd518a76b888b11ecbd4f51',
    social_type: 'dzen',
  },
  {
    icon: OdnoklassnikiIcon,
    link: 'https://ok.ru/group/70000001233998',
    social_type: 'ok',
  },
];
